<template>
  <div class="goods-edit">
    <mobile-preview @handleSaveClick="handleSaveClick" @htmlChang="htmlChang"
      v-model:copywritingValue="copywritingValue" @handleLastClick="handleLastClick"></mobile-preview>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import MobilePreview from '@/components/mobile-preview/mobile-preview.vue'
import emitter from '@/utils/eventbus'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
export default defineComponent({
  props: {

  },
  components: {
    MobilePreview
  },
  setup() {
    const store = useStore()
    const handleLastClick = () => {
      emitter.emit('changeUpdateGoodsInfoPreviousClick')
    }

    const copywritingValue = ref('')
    const htmlValue = ref('')
    const htmlChang = (value) => {
      htmlValue.value = value
    }

    const handleSaveClick = () => {
      store.dispatch('goods/addGoodsShareinfoAction', {
        companyId: COMPANY_ID,
        goodId: '',
        shareWords: copywritingValue.value,
        goodInfo: htmlValue.value,
        isUpdate: true

      })
      copywritingValue.value = ''
    }

    emitter.on('changeUpdateGoodsShareWords', shareWords => {
      copywritingValue.value = shareWords
    })

    return {
      handleLastClick,
      copywritingValue,
      htmlChang,
      COMPANY_ID,
      handleSaveClick
    }

  }
})
</script>

<style scoped lang="less">
</style>




