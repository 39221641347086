<template>
  <div class="format-form">
    <div>
      <el-input size="small" v-model="ruleForm.paraName" placeholder="规格名"></el-input>
    </div>
    <div>
      <el-input size="small" v-model="ruleForm.paraDetail" placeholder="规格值"></el-input>
    </div>
    <div>
      <el-input size="small" v-model="ruleForm.salePrice" placeholder="价格">
        <template #suffix>| 元</template>
      </el-input>
    </div>

    <div class="promPrice">
      <el-input size="small" v-model="ruleForm.promPrice" placeholder="划线价">
        <template #suffix>| 元</template>
      </el-input>
      <el-button color="#409EFF" size="small" v-if="ruleForm.isUpdate" @click="handleUpdateClick" type="primary" plain>修改</el-button>
      <el-button color="#409EFF" size="small" v-if="isFirst" @click="handleAddClick" class="add" type="primary" plain>新增</el-button>
      <el-button size="small" v-else @click="handleRemoveClick" class="remove" type="danger" plain>删除</el-button>
    </div>
  </div>
</template>

<script lang="js">
import store from '@/store'
import { defineComponent, reactive, ref, computed } from 'vue'

export default defineComponent({
  props: {
    isFirst: {
      type: Boolean,
      default: false
    },
    ruleForm: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {

  },
  emits: ['addFormatFormChange', 'removeFormatFormChange'],
  setup(props, { emit }) {
    const item = computed(() => props.ruleForm)
    const handleAddClick = () => {
      emit('addFormatFormChange')
    }

    const handleRemoveClick = () => {
      emit('removeFormatFormChange')
    }

    const handleUpdateClick = () => {
      let obj = {
        paraId: item.value.id,
        paraName: item.value.paraName,
        paraDetail: item.value.paraDetail,
        salePrice: item.value.salePrice,
        promPrice: item.value.promPrice
      }
      store.dispatch('goods/updateGoodsFormatAction', obj)
    }

    return {
      item,
      handleUpdateClick,
      handleAddClick,
      handleRemoveClick
    }

  }
})
</script>

<style scoped lang="less">
.format-form {
  max-width: 1000px;
  min-width: 400px;
  display: flex;
  margin: 16px 0;

  &>div {
    flex: 1;
    margin-right: 10px;
  }

  &:deep(.el-select) {
    max-width: 160px;


  }

  &:deep(.el-input) {
    max-width: 160px;
  }


  .promPrice {
    display: flex;
    align-items: center;
    flex: 1.3;

    &:deep(.el-button) {
      margin-left: 10px;
    }
  }

}
</style>
