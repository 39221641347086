<template>
  <div class="add-tag-dialog">
    <page-dialog @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="商品标签管理"
      :isShowFooter="true">
      <template #default>
        <div class="top-form">
          <el-input size="small" v-model="tagValue" placeholder="请输入标签名称" />
          <el-button plain color="#409EFF" size="small" class="add-btn" type="primary" @click="handleAddTagClick">新增标签</el-button>
        </div>
        <div class="tag-list">
          <template :key="item" v-for="item in goodsTagList">
            <el-tag @dblclick="handleCheckBoxDbclick(item)" @close="handleTagClose(item)" closable>
              {{ item.tagName }}
            </el-tag>
          </template>
        </div>
      </template>

      <template #footer>
        <div class="remove">
        </div>
      </template>
    </page-dialog>
    <page-dialog title="更改标签" @changeDialogVisible="changeUpdateNameDialogVisible"
      :dialogVisible="dialogUpdateNameVisible">
      <div class="update-name-form">
        <el-input size="small" v-model="updateCategoryNameValue" placeholder="请输入标签名称" />
        <el-button color="#409EFF" class="sure-btn" plain size="small" type="primary" @click="handleUpdateTagNameClick">确定</el-button>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { tips } from '@/utils/tips'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import emitter from '@/utils/eventbus'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const ruleForm = reactive({
      name: ''
    })
    const companyID = ref(COMPANY_ID)
    const currentChooseTag = ref([])
    const tagValue = ref('')
    const tagList = ['全身按摩', '舒缓按摩', '上身按摩', '腿部按摩']
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const handleAddTagClick = () => {
      store.dispatch('goods/addTagAction', { tagName: tagValue.value, companyId: companyID.value })
    }

    const initPage = () => {
      store.dispatch('goods/getGoodsTagAction', { companyId: companyID.value, tagKind: 2 })
    }
    initPage()


    emitter.on('goodsTagListChange', () => {
      initPage()
      tagValue.value = ''
    })

    emitter.on('refreshTagListChange', () => {
      initPage()
    })

    const tagState = useState('goods', ['goodsTagList'])

    const handleRemoveClick = () => {
      store.dispatch('goods/removeGoodsTagAction', [...currentChooseTag.value])
      // console.log(currentChooseTag.value);
    }

    const dialogUpdateNameVisible = ref(false)
    const changeUpdateNameDialogVisible = (flag) => {
      dialogUpdateNameVisible.value = flag
    }
    const updateCategoryNameValue = ref('')
    const currentUpdateTagId = ref('')
    const handleUpdateTagNameClick = () => {
      store.dispatch('goods/updateGoodsTagName', [currentUpdateTagId.value, updateCategoryNameValue.value])
      dialogUpdateNameVisible.value = false
    }

    emitter.on('updateTagNameChangeAction', () => {
      initPage()
    })

    const handleCheckBoxDbclick = (item) => {
      dialogUpdateNameVisible.value = true
      currentUpdateTagId.value = item.tagId
      updateCategoryNameValue.value = item.tagName
    }

    const handleTagClose = item => {
      store.dispatch('goods/removeGoodsTagAction', [item])
    }

    return {
      handleTagClose,
      changeUpdateNameDialogVisible,
      dialogUpdateNameVisible,
      changeDialogVisible,
      ruleForm,
      tagList,
      currentChooseTag,
      tagValue,
      handleAddTagClick,
      companyID,
      COMPANY_ID,
      ...tagState,
      handleRemoveClick,
      handleCheckBoxDbclick,
      updateCategoryNameValue,
      handleUpdateTagNameClick,
      currentUpdateTagId
    }

  }
})
</script>

<style scoped lang="less">
.add-tag-dialog {
  .update-name-form {
    display: flex;

    .sure-btn {
      margin-left: 10px;
    }
  }

  &:deep(.el-checkbox-button--small) {
    margin-top: 10px;
    margin-right: 10px;
  }

  .remove {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tips {
      color: #ccc;
      font-size: 12px;
    }
  }

  .tag-list {
    display: flex;
    padding-top: 20px;

    &>span {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .top-form {
    display: flex;

    .add-btn {
      margin-left: 10px;
    }
  }

  &:deep(.el-select) {
    max-width: 160px;
  }
}
</style>
