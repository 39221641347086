<template>
  <div class="add-form">
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="updateGoodsRules" label-width="120px" class="demo-ruleForm"
      :size="formSize">
      <el-form-item class="form-input" label="名称：" prop="goodName">
        <el-input v-model="ruleForm.goodName"></el-input>
      </el-form-item>
      <el-form-item class="form-input" label="分类：" prop="category">
        <el-cascader v-model="ruleForm.category" :options="goodsCategoryList" :props="categoryProps" clearable
          @change="handleCascaderChange" />
        <span class="other" @click="handleGoodsCategoryClick">管理产品分类</span>

      </el-form-item>
      <el-form-item class="form-input" label="标签：" prop="tagId">
        <el-select multiple v-model="ruleForm.tagId" class="m-2" placeholder="请选择">
          <el-option v-for="item in goodsTagList" :key="item.value" :label="item.tagName" :value="item.tagId">
          </el-option>
        </el-select>
        <span class="other" @click="handleGoodsTagClick">管理产品标签</span>

      </el-form-item>

      <el-form-item label="图片：" prop="desc">
        <upload-img :file-list="imgUploadFileList" ref="imgUploadRef" @handleUploadSuccess="handleImgUploadSuccess"
          @handleUploadRemove="handleImgUploadRemove"></upload-img>
        <div class="tips">建议尺寸：800*800像素，你可以拖拽图片调整顺序，最多上传5张</div>
      </el-form-item>

      <el-form-item label="视频：" prop="desc">
        <upload-video :fileList="videoUploadFileList" ref="videoUploadRef"
          @handleUploadSuccess="handleVideoUploadSuccess" @handleUploadRemove="handleVideoUploadRemove"></upload-video>
        <div class="tips">建议上传视频时长为9-30秒，视频宽高比为16:9</div>
      </el-form-item>

      <el-form-item label="规格：" prop="desc">
        <div class="format">
          <div class="titles">
            <div>规格名</div>
            <div>规格值</div>
            <div>价格</div>
            <div class="huaxian-price">
              划线价

            </div>
          </div>

          <template :key="item" v-for="(item, index) in formatForm">
            <format-form :isFirst="index == 0" @removeFormatFormChange="hadleRemoveFormatFormChange(index)"
              @addFormatFormChange="handleAddFormatFormChange" :ruleForm="formatForm[index]"></format-form>
          </template>
        </div>
      </el-form-item>
      <el-form-item prop="desc">
        <el-button @click="handleSaveGoodsClick(ruleFormRef)" class="next-btn" type="primary">下一步</el-button>
      </el-form-item>
    </el-form>
    <add-goods-category-dialog :dialogVisible="addCategoryDialogVisible" @changeDialogVisible="changeAddCategoryDialog">
    </add-goods-category-dialog>
    <add-tag-dialog :dialogVisible="addTagDialogVisible" @changeDialogVisible="changeAddTagDialog"></add-tag-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch, nextTick } from 'vue'
import addGoodsCategoryDialog from './cpns/add-goods-category-dialog/add-goods-category-dialog.vue'
import addTagDialog from './cpns/add-tag-dialog/add-tag-dialog.vue'
import pageUpload from '@/components/page-upload/page-upload.vue'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import { categoryProps } from './config'
import UploadVideo from '@/components/page-upload/upload-video/upload-video.vue'
import { useStore } from 'vuex'
import FormatForm from './base-ui/format-form/format-form'
import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'
import { useRoute } from 'vue-router'
import { UPLOAD_BASE_URL } from '@/common/constant.js'
import emitter from '@/utils/eventbus'
import { updateGoodsRules } from '../../../../config'
import { ElMessage } from 'element-plus'
import { savePic } from '@/service/main/goods'
import { tips } from '@/utils/tips'

export default defineComponent({
  props: {

  },
  components: {
    addGoodsCategoryDialog,
    addTagDialog,
    pageUpload,
    UploadVideo,
    FormatForm,
    UploadImg
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const currentGoodsId = computed(() => route.query.goodsId)
    const updateGoodsDetail = computed(() => store.state.goods.updateGoodsDetail)
    const goodsFormatList = computed(() => store.state.goods.goodsFormatList)
    const goodsPicList = computed(() => store.state.goods.goodsPicList)
    watch(updateGoodsDetail, (n) => {
      if (n != null) {
        videoUploadFileList.value = []
        ruleForm.goodName = n.goodName
        ruleForm.cataId = n.cataId
        let tagList = []
        try {
          n.tagId.split(',').forEach(item => {
            tagList.push(Number(item))
          })
        } catch (error) {
          // console.log(error);
        }

        ruleForm.tagId = tagList
        ruleForm.subCataId = n.subCataId
        ruleForm.goodVideo = n.goodVideo
        if (n.goodVideo.trim().length > 0) {
          videoUploadFileList.value.push({
            name: 'video',
            url: `${UPLOAD_BASE_URL}/${n.goodVideo}`
          })
        }

        if (n.subCataId == null) {
          ruleForm.category = [n.cataId]
        } else {
          ruleForm.category = [n.cataId, n.subCataId]
        }

        emitter.emit('changeUpdateCardCardInfo', n.goodInfo)
        emitter.emit('changeUpdateGoodsShareWords', n.shareWords)
      }


    })

    const ruleFormRef = ref()

    watch(goodsFormatList, (n) => {
      formatForm.value = []
      n.forEach(item => {
        formatForm.value.push(
          {
            id: item.paraId,
            paraName: item.paraName,
            paraDetail: item.paraDetail,
            salePrice: item.salePrice / 100,
            promPrice: item.promPrice / 100,
            isUpdate: true
          }
        )
      })


    })

    const imgUploadFileList = ref([])
    watch(goodsPicList, (n) => {
      imgUploadFileList.value = []
      nextTick(() => {
        n.forEach((item, index) => {
          imgUploadFileList.value.push({
            name: `${index}`,
            url: `${UPLOAD_BASE_URL}/${item.picUrl}`,
            // url: item.picUrl,
            id: item.picId
          })
          goodsImgList.value.push(item.picUrl)
        })
      })

    })

    const handleImgUploadRemove = (lastUrl) => {
      const currentRemove = imgUploadFileList.value.find((item) => {
        const arr = item.url.split('/uploads')
        const url = '/uploads' + arr[1]
        return url == lastUrl
      })

      if (currentRemove != undefined) {
        let obj = {
          picId: currentRemove.id,
          relateKind: 2,
          relateId: currentGoodsId.value

        }
        store.dispatch('goods/removeGoodsPic', obj)
        const removeIndex = goodsImgList.value.findIndex(item => item == lastUrl)
        goodsImgList.value.splice(removeIndex, 1)
      } else {
        const removePic = addPicListRecord.value.find(ele => ele.url == lastUrl)
        if (removePic != undefined) {
          let obj = {
            picId: removePic.id,
            relateKind: 2,
            relateId: currentGoodsId.value

          }
          store.dispatch('goods/removeGoodsPic', obj)
          const removeIndex = addPicListRecord.value.findIndex(item => item.url == lastUrl)
          if (removeIndex != -1) {
            addPicListRecord.value.splice(removeIndex, 1)
          }
        }
      }

    }

    const addPicListRecord = ref([])

    const ruleForm = reactive({
      companyId: COMPANY_ID,
      goodName: '',
      cataId: '',
      tagId: "",
      subCataId: "",
      goodVideo: "",
      category: ''
    })

    const formatForm = ref([])
    const updateFormatForm = ref([])
    const videoUploadFileList = ref([])

    const handleVideoUploadRemove = (url) => {
      ruleForm.goodVideo = ''
      const index = videoUploadFileList.value.findIndex(item => {
        return item.url == url
      })
      if (index != -1) {
        videoUploadFileList.value.splice(index, 1)
      }

    }

    const emptyRuleForm = () => {
      for (let key in ruleForm)
        if (key != 'companyId') {
          ruleForm[key] = ''
        } else {
          ruleForm[key] = COMPANY_ID
        }
    }

    const addCategoryDialogVisible = ref(false)
    const addTagDialogVisible = ref(false)

    const handleGoodsCategoryClick = () => {
      addCategoryDialogVisible.value = true
    }

    const changeAddTagDialog = (flag) => {
      addTagDialogVisible.value = flag
    }

    const handleGoodsTagClick = () => {
      addTagDialogVisible.value = true
    }

    const changeAddCategoryDialog = (flag) => {
      addCategoryDialogVisible.value = flag
    }

    const handleUploadSuccess = (res, type) => {

    }


    const addFormState = useState('goods', ['goodsTagList', 'goodsCategoryList'])


    const handleCascaderChange = (arrId) => {
      ruleForm.cataId = arrId[0]
      if (arrId[1]) {
        ruleForm.subCataId = arrId[1]
      }
    }

    const handleVideoUploadSuccess = (res) => {
      ruleForm.goodVideo = res.response.data.fileName
      videoUploadFileList.value.push({
        name: 'video',
        url: `${UPLOAD_BASE_URL}/${res.response.data.fileName}`
      })

    }

    const videoUploadRef = ref('')
    const handleSaveGoodsClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          let flag = true
          if (formatForm.value.length == 0) {
            ElMessage({
              message: '最少添加一个善品规格',
              type: 'warning',
            })
            return
          }

          formatForm.value.forEach(item => {
            for (let key in item) {
              if (String(item[key]).trim().length == 0) {
                ElMessage({
                  message: '请正确填写商品规格',
                  type: 'warning',
                })
                flag = false
                break
              }
            }
          })

          if (flag) {
            ruleForm.tagId = ruleForm.tagId.join()
            let obj = {
              ruleForm: {
                goodId: currentGoodsId.value,
                ...ruleForm
              },
              formatForm: formatForm.value,
              goodId: currentGoodsId.value
            }
            store.dispatch('goods/updateGoodsAcction', obj)
            emitter.emit('changeUpdateGoodsInfoNextClick')
          }

        } else {
          // console.log('error submit!', fields)
        }
      })




    }

    const handleAddFormatFormChange = () => {
      formatForm.value.push({
        companyId: COMPANY_ID,
        paraName: "",
        paraDetail: "",
        salePrice: "",
        promPrice: "",
      })
    }

    const hadleRemoveFormatFormChange = (index) => {
      const format = formatForm.value.splice(index, 1)

      store.dispatch('goods/removeGoodsFormatAction', {
        companyId: COMPANY_ID,
        paraId: format[0].id
      })

    }


    const imgUploadRef = ref(null)
    const goodsImgList = ref([])
    const updateGoodsImgList = ref([])
    const handleImgUploadSuccess = async (res) => {
      const url = res.response.data.fileName
      let obj = {
        picUrl: url,
        relateKind: 2,
        relateId: currentGoodsId.value
      }
      const result = await savePic(obj)
      addPicListRecord.value.push({
        url,
        id: result.data
      })
      tips(res)
    }

    const initPage = () => {
      store.dispatch('goods/getGoodsPicAction', { relateId: currentGoodsId.value, relateKind: 2 })
      store.dispatch('goods/getGoodsFormat', { companyId: COMPANY_ID, goodId: currentGoodsId.value })
      store.dispatch('goods/getGoodDetailAction', { companyId: COMPANY_ID, goodId: currentGoodsId.value })
    }

    watch(() => route.path, (n) => {
      if (n == '/main/goods/update') {
        initPage()
        emitter.emit('changeUpdateGoodsInfoPreviousClick')
      }
    })

    emitter.on('refreshGoodsUpdataPage', () => {
      initPage()
    })

    initPage()

    return {
      addPicListRecord,
      updateGoodsRules,
      ruleForm,
      changeAddCategoryDialog,
      changeAddTagDialog,
      addCategoryDialogVisible,
      addTagDialogVisible,
      handleGoodsCategoryClick,
      handleGoodsTagClick,
      handleUploadSuccess,
      ...addFormState,
      categoryProps,
      handleCascaderChange,
      handleVideoUploadSuccess,
      handleSaveGoodsClick,
      emptyRuleForm,
      videoUploadRef,
      formatForm,
      handleAddFormatFormChange,
      hadleRemoveFormatFormChange,
      handleImgUploadSuccess,
      imgUploadRef,
      goodsImgList,
      updateGoodsDetail,
      currentGoodsId,
      goodsFormatList,
      videoUploadFileList,
      imgUploadFileList,
      handleImgUploadRemove,
      updateGoodsImgList,
      updateFormatForm,
      handleVideoUploadRemove,
      ruleFormRef

    }

  }
})
</script>

<style scoped lang="less">
.add-form {
  .tips {
    color: rgba(128, 128, 128, 1);
    font-size: 12px;
  }

  .next-btn {
    margin-top: 40px;
    width: 156px;
    height: 48px;
  }

  .form-input {
    .other {
      display: inline-block;
      margin-left: 16px;
      color: rgba(42, 130, 228, 1);
      font-size: 12px;
      cursor: pointer;
    }

    &:deep(.el-input, .el-select) {
      width: 30%;
      width: 340px;
    }

    &:deep(.el-select) {
      width: 340px;

      .el-input {
        width: 100%;
      }
    }
  }

  .format {
    max-width: 1000px;
    min-width: 400px;

    .titles {
      display: flex;
      color: rgba(80, 80, 80, 1);
      background-color: rgba(239, 239, 239, 1);
      font-size: 12px;
      padding: 0 10px;

      .huaxian-price {
        flex: 1.3;
      }

      div {
        flex: 1;
        width: 0;

        span {
          font-size: 13px;
          color: rgb(102, 177, 255);
        }
      }
    }
  }
}
</style>
