export const breadcrumbList = [
  {
    name: '商品管理',
    path: ''
  },
  {
    name: '产品列表',
    path: '/main/goods/list'
  },
  {
    name: '修改产品',
    path: ''
  }
]

export const updateGoodsRules = {
  goodName: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
  category: [{ required: true, message: '请选择服务分类', trigger: 'blur' }],
  tagId: [{ required: true, message: '请选择标签', trigger: 'blur' }]
}
